'use client';

import { Spinner, useNeedsLoadingIcon } from '@/components/Spinner/Spinner';
import { useHasData } from './useHasData';
import ContentWithDefault from '@/components/contentWithDefault/page';
import { PropsWithChildren } from 'react';

export interface LoadingContentProps extends PropsWithChildren {
    data?: unknown;
    loading?: boolean | boolean[];
    loadingContent?: React.ReactNode;
    hasData?: boolean;
    noDataContent?: React.ReactNode;
    debug?: boolean;
}

/**
 * TODO: Look into switching to Suspense at some point
 *
 * (useSWR currently doesn't recomment it for fetching data)
 * https://swr.vercel.app/docs/suspense
 *
 * @param props
 * @returns
 */
export default function LoadingContent(props: LoadingContentProps): React.ReactNode {
    // Use hooks to reduce the amount of change
    const loading = useNeedsLoadingIcon(props.loading);
    const hasData = useHasData(props.data, props.hasData);

    if (loading) {
        return <ContentWithDefault content={props.loadingContent} defaultContent={<Spinner />} />;
    }

    if (!hasData) {
        return <ContentWithDefault content={props.noDataContent} />;
    }

    return props.children;
}
